import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { map, Observable, tap } from 'rxjs';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private perm: PermissionService,
    private toast: HotToastService,
    private router: Router,
  ) { }

  routePermissions: { [path: string]: string } = {
    '/server': 'LICENSES.VIEW',
    '/configs': 'CONFIGS.VIEW',
    '/logs': 'LOGS.VIEW',
    '/identities': 'IDENTITIES.VIEW',
    '/apollo': 'APOLLO.VIEW',
    '/whitelist': 'WHITELIST.VIEW',
    '/webhooks': 'WEBHOOKS.VIEW',
    '/triggers': 'TRIGGERS.VIEW',
    '/threats': 'THREATS.VIEW',
    '/docs': 'DOCS.VIEW',
    '/users': 'USERS.VIEW',
  };

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const path = state.url;

    const permName = this.routePermissions[path];
    if (!permName) {
      return true;
    }

    return this.perm.permissions().pipe(
      map((perms) => { return perms?.includes(permName) || perms?.includes('ADMIN') }),
      tap(
        (hasPermission) =>
          !hasPermission &&
          this.toast.error(
            `You must have the ${permName} permission to view this page.`,
          ),
      ),
      map((hasPermission) => {
        if (!hasPermission) {
          return this.router.parseUrl('/dashboard');
        }

        return hasPermission;
      }),
    );
  }
}
