import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from 'src/libs/api/api.service';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';

@Component({
    selector: 'fini-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AuthenticationComponent implements OnInit {
  constructor(
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  status$ = new BehaviorSubject<'loading' | 'failed' | 'success'>('loading');
  statusMessage$ = new BehaviorSubject('');
  showTimeout$ = new BehaviorSubject(false);

  // this aids
  ngOnInit(): void {
    this.loadScript('https://fini.dev/Public/client.js');
    this.loadScript('https://fini.dev/Public/fp.js', () => {
      setTimeout(() => {
        (window as any).Fingerprint2.get((components: any) => {
          for (const n of [21, 17, 7, 6, 0]) {
            components.splice(n, 1);
          }

          const values = components.map((component: any) => {
            return component.value;
          });
          const hash = (window as any).Fingerprint2.x64hash128(
            values.join(''),
            31
          );

          const browserTests = [
            'audio',
            'availableScreenResolution',
            'canvas',
            'colorDepth',
            'cookies',
            'cpuClass',
            'deviceDpi',
            'doNotTrack',
            'indexedDb',
            'installedFonts',
            'language',
            'localStorage',
            'pixelRatio',
            'platform',
            'plugins',
            'processorCores',
            'screenResolution',
            'sessionStorage',
            'timezoneOffset',
            'touchSupport',
            'userAgent',
            'webGl',
          ];

          from((window as any).imprint.test(browserTests))
            .pipe(
              switchMap((result) =>
                this.api.get<ApiResponse>('https://fini.dev/api/web-auth', {
                  id: hash,
                  id2: result,
                  pId: this.route.snapshot.queryParams['id'] ?? '',
                })
              )
            )
            .subscribe((res) => {
              if (res.success) {
                this.status$.next('success');
              } else {
                this.status$.next('failed');
                this.statusMessage$.next(res.message);
              }
              this.cd.detectChanges();
            });
        });
      }, 1000);
    });
  }

  loadScript(url: string, onload: any = null) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    if (onload) {
      node.onload = onload;
    }

    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
