@let ips = (ipList$ | async) ?? [];
<ng-container>
  <div class="d-flex justify-content-between align-items-start">
    <div class="page-title">
      <div class="d-flex align-items-center gap-2 mb-2">
        <h2 class="mb-0">
          {{ 'licenses.ips.title' | translate }}
        </h2>
        <fini-tooltip>
          <mat-chip class="py-1 ms-2 h-auto">
            <span class="text-small">
              {{ ips.length ?? '-' }}/{{ max(0, maxIps$ | async) }}
            </span>
          </mat-chip>
          <div content class="text-xsmall">
            {{
              'licenses.ips.tooltip' | translate: { maxIps: maxIps$ | async }
            }}
          </div>
        </fini-tooltip>
      </div>
      <span>
        {{ 'licenses.ips.subtitle' | translate }}
      </span>
    </div>
    @let write = 'WHITELIST.WRITE' | permission | async;
    <ng-container *ngIf="write">
      <button
        class="button primary"
        (click)="addNew$.next(true)"
        [disabled]="atLimit$ | async"
      >
        {{ 'licenses.ips.add-new' | translate }}
      </button>
    </ng-container>
  </div>
  <div class="whitelist" *ngIf="ips; else loading">
    <div class="row">
      <div class="col-12 list">
        <table
          class="fini-table no-padding w-100"
          *ngIf="ips.length; else noIps"
        >
          <thead>
            <tr>
              <td>{{ 'licenses.ips.ip-address' | translate }}</td>
              <td *ngIf="write"></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ip of ips">
              <td class="w-100">{{ ip.ip_addr }}</td>
              <td *ngIf="write">
                <button
                  class="button secondary small"
                  (click)="remove(ip)"
                  *ngIf="ip.editable === 'true'"
                  [disabled]="loading$ | async"
                >
                  <i class="material-symbols-outlined">close</i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <fini-modal *ngIf="addNew$ | async" (closed)="close()">
      <div class="std-modal">
        <h3>{{ 'licenses.ips.modal.title' | translate }}</h3>
        <div class="content-group">
          <div class="form-group-split">
            <label>{{ 'licenses.ips.modal.ip-address' | translate }}:</label>
            <input
              class="input-text"
              type="text"
              placeholder="127.0.0.1"
              [(ngModel)]="newIp"
            />
          </div>
        </div>
        <button
          class="button primary"
          (click)="save()"
          [disabled]="loading$ | async"
        >
          {{ 'buttons.create' | translate }}
        </button>
      </div>
    </fini-modal>
  </div>
</ng-container>
<ng-template #noIps>
  <p>
    {{ 'licenses.ips.no-ips' | translate }}
  </p>
</ng-template>
<ng-template #loading>
  <div class="loading mt-5 text-center">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>
