import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ApiThreat } from 'src/libs/models/Threats.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';

@Component({
    selector: 'fini-hc-ban-details',
    imports: [TranslateModule, CommonModule],
    templateUrl: './hc-ban-details.component.html',
    styleUrl: './hc-ban-details.component.scss'
})
export class HcBanDetailsComponent {
  @Input() ban: ApiThreat | null = null;

  loading = signal(true);

  ngOnInit() {
    if (this.ban !== null) {
      this.loading.set(false);
    }
  }

  ngOnChanges() {
    if (this.ban && this.ban !== null) {
      this.loading.set(false);
    } else {
      this.loading.set(true);
    }
  }
}
