import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { StateService } from '../state/state.service';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HotToastService } from '@ngxpert/hot-toast';
import { ApiUser } from '../models/Profile.model';
import { ApiResponse } from '../models/ApiResponse.model';

@Component({
  selector: 'fini-security-check',
  templateUrl: './security-check.component.html',
  styleUrls: ['./security-check.component.scss'],
  standalone: false,
})
export class SecurityCheckComponent implements OnInit, OnDestroy {
  constructor(
    private state: StateService,
    private api: ApiService,
    private toast: HotToastService,
  ) {}

  showSecurityCheck = signal(false);
  user = this.state.get<ApiUser>('user');

  provider = signal<'app' | 'password' | 'email' | ''>('');
  providerIcon = computed(() => {
    switch (this.provider()) {
      case 'app':
        return 'devices';
      case 'password':
        return 'key';
      case 'email':
        return 'mail_lock';
      default:
        return 'lock';
    }
  });
  code = signal('');

  ngOnInit(): void {
    window.addEventListener('security:check', this.SecurityCheck.bind(this));
  }
  private SecurityCheck(ev: any) {
    const provider = ev?.detail?.provider;
    if (!provider) {
      this.toast.error('Invalid MFA provider');
    }

    if (!this.showSecurityCheck()) {
      this.toast.warning('Verify your identity to continue.');
    }
    this.provider.set(provider);
    this.showSecurityCheck.set(true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('security:check', this.SecurityCheck.bind(this));
  }

  check() {
    this.api
      .post<ApiResponse>('session/security', {
        code: this.code(),
        provider: this.provider(),
      })
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.showSecurityCheck.set(false);
            this.code.set('');
            window.dispatchEvent(new CustomEvent('security:check:success'));
          } else {
            this.toast.error(res.message);
          }
        },
        error: console.error,
      });
  }
}
