import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HotToastService } from '@ngxpert/hot-toast';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from 'src/libs/api/api.service';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { TooltipModule } from 'src/libs/tooltip/tooltip.module';

@Component({
  selector: 'fini-whitelist-submission',
  templateUrl: './whitelist-submission.component.html',
  styleUrls: ['./whitelist-submission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TooltipModule,
    CustomPipesModule,
  ],
})
export class WhitelistSubmissionComponent {
  constructor(
    private api: ApiService,
    private toast: HotToastService,
  ) { }

  @Output() submitted = new EventEmitter();

  state = signal<'initial' | 'reason' | 'preview'>('initial');
  message = signal('');
  isLoading = signal(false);

  whitelist = {
    player_uid: '',
    reason: '',
    admittance: '',
  };

  checkUid() {
    if (!this.whitelist.player_uid) {
      this.message.set('Please enter a player UID');
      return;
    }

    this.isLoading.set(true);
    this.api
      .post<ApiResponse>('whitelist/check-uid', this.whitelist)
      .subscribe((res) => {
        if (res.success) {
          this.state.set('reason');
          this.message.set('');
        } else {
          this.message.set(res.message);
        }
        this.isLoading.set(false);
      });
  }

  preview() {
    this.state.set('preview');
  }

  submit() {
    this.api.post('whitelist/submit', this.whitelist).subscribe((res) => {
      if (res.success) {
        if (res.auto_accepted) {
          this.toast.success('Whitelist request auto-accepted!');
        } else {
          this.toast.success('Whitelist request submitted!');
        }
        this.whitelist = {
          player_uid: '',
          reason: '',
          admittance: '',
        };
        this.state.set('initial');
        this.submitted.emit();
      } else {
        this.toast.error(res.message);
      }
    });
  }
}
