import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthGuard } from 'src/libs/auth/auth.guard';
import { GuideService } from 'src/libs/guides/guides.service';
import { StateService } from 'src/libs/state/state.service';

@Component({
  selector: 'fini-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent {
  title = 'fini-dev';
  localhost = false;
  NO_SNOW = false;
  constructor(
    private route: Router,
    private authGuard: AuthGuard,
    state: StateService,
    translate: TranslateService,
    toast: HotToastService,
    private guideService: GuideService,
  ) {
    state.set({ theme: state.get('theme') });
    translate.use(state.get('language'));

    state.select('theme').subscribe((theme) => {
      toast.defaultConfig.theme = theme == 'light' ? 'toast' : 'snackbar';
    });

    this.guideService.isCompleted('setup').subscribe((isCompleted) => {
      if (!isCompleted) {
        this.guideService.trigger('setup');
      }
    });

    if (window?.location?.href?.includes('localhost')) {
      this.localhost = true;
    }

    if (window?.localStorage?.getItem('NO_SNOW') !== null) {
      this.NO_SNOW = true;
    }
  }

  // todo: pull the hnotifications from an api endpoint
  notifications: any[] = [];

  guard = this.authGuard;
  loggedInPage$ = new BehaviorSubject(false);

  currentRoute$ = this.route.events.pipe(
    // tap(console.log),
    filter((ev): ev is ActivationEnd => ev instanceof ActivationEnd),
    map((ev: ActivationEnd) => {
      const url = ev?.snapshot?.routeConfig?.path || '';
      return url[0] === '/' ? url.substr(1) : url;
    }),
    map((url) => (url === '**' ? 'not-found' : url)),
    tap((url) => {
      // url only contains the first part of the path
      return this.loggedInPage$.next(
        ![
          'login',
          'forgot',
          'register',
          'reset-password',
          'authenticate',
          'not-found',
          'utils',
          '',
        ].includes(url),
      );
    }),
  );
}
