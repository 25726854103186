import { Component, OnInit, signal } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';
import { ApiOnboarding, ApiOnboardingWorkflow } from '../models/Profile.model';

@Component({
    selector: 'fini-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    standalone: false
})
export class OnboardingComponent implements OnInit {
  constructor(private api: ApiService, private router: Router) {}

  noShowPages = ['/setup'];

  showOnboarding = signal(false);
  shownWorkflows = signal<ApiOnboardingWorkflow[]>([]);

  ngOnInit() {
    if (this.noShowPages.includes(this.router.url)) {
      return;
    }

    this.api.get<ApiOnboarding>('user/onboard').subscribe((res) => {
      if (res.success) {
        const workflows = res.workflows.filter((workflow) =>
          workflow.steps.some((step) => !step.completed)
        );

        if (workflows.length) {
          this.showOnboarding.set(true);
          this.shownWorkflows.set(workflows);
        }
      }
    });
  }

  handleAction(action: string) {
    this.showOnboarding.set(false);
    this.router.navigate([action]);
  }
}
