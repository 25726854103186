import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, switchMap } from 'rxjs';
import { ApiService } from 'src/libs/api/api.service';
import { HoverCardService } from 'src/libs/hover-card/hover-card.service';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import { ApiThreatList } from 'src/libs/models/Threats.model';
import { ApiWhitelistRequest } from 'src/libs/models/Whitelist.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { TooltipModule } from 'src/libs/tooltip/tooltip.module';

@Component({
  selector: 'fini-whitelist-item',
  templateUrl: './whitelist-item.component.html',
  styleUrls: ['./whitelist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    CustomPipesModule,
    TooltipModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class WhitelistItemComponent implements OnInit {
  constructor(
    private api: ApiService,
    private toast: HotToastService,
    private hc: HoverCardService,
  ) {}

  @Input() whitelist: ApiWhitelistRequest;

  isResponseVisible = signal(false);
  defaultRejectReason = `Whitelisting request rejected. This player does not meet the minimum whitelisting requirements, such as minimum 30 days since the ban or the whitelisting request was insufficient.`;

  updateTrigger$ = new Subject<void>();

  updatePipe$ = this.updateTrigger$.pipe(
    debounceTime(500),
    switchMap(() =>
      this.api.post<ApiResponse>('whitelist/update', this.whitelist),
    ),
  );

  update() {
    this.updateTrigger$.next();
  }

  ngOnInit(): void {
    this.isResponseVisible.set(!!this.whitelist.response);
    this.updatePipe$.subscribe((res) => {
      if (res.success) {
        this.toast.success('Whitelist updated');
      } else {
        this.toast.error('Failed to update whitelist ' + res.message);
      }
    });
  }

  accept() {
    this.whitelist.status = 'accepted';
    const { response } = this.whitelist;
    if (
      response == this.defaultRejectReason ||
      response.startsWith('Removed from whitelist')
    ) {
      this.whitelist.response = '';
    }

    this.update();
  }

  reject() {
    this.whitelist.status = 'rejected';
    if (!this.whitelist.response) {
      this.whitelist.response = this.defaultRejectReason;
    }
    this.isResponseVisible.set(true);
    this.update();
  }

  remove() {
    this.whitelist.status = 'removed';
    this.update();
  }

  daysSinceBan(num: number) {
    return Math.floor((Date.now() / 1000 - num) / 60 / 60 / 24);
  }

  debounce: number = null;
  showHovercard(whitelist: ApiWhitelistRequest) {
    this.debounce = window.setTimeout(() => {
      this.hc.show('ban-details', {});
      // MUST BE CACHED, otherwise it'll be rate-limited to fuck.
      this.api
        .get<ApiThreatList>(
          'globals/search',
          {
            search: whitelist.player_uid,
            limit: 1,
          },
          true,
        )
        .subscribe((res) => {
          if (res.success) {
            const ban = res.bans[0];

            if (!ban) return;
            this.hc.show('ban-details', { ban });
          } else {
            this.toast.error(res.message);
          }
        });
    }, 250);
  }

  hideHovercard() {
    clearTimeout(this.debounce);
    this.hc.hide();
  }
}
