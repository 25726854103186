<div>
  <ng-container [ngTemplateOutlet]="pageChanger"></ng-container>
  <fini-whitelist-item
    *ngFor="let wl of whitelists"
    [whitelist]="wl"
  ></fini-whitelist-item>
  <ng-container [ngTemplateOutlet]="pageChanger"></ng-container>
</div>

<ng-template #pageChanger>
  <div class="page-changer" *ngIf="getPageCount() > 0">
    <i class="material-symbols-outlined" (click)="changePage(-1)">
      navigate_before
    </i>
    <span>{{ page() + 1 }}/{{ getPageCount() + 1 }}</span>
    <i class="material-symbols-outlined" (click)="changePage(1)">
      navigate_next
    </i>
  </div>
</ng-template>
