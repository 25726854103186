import { Component, OnInit, signal } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { ApiService } from 'src/libs/api/api.service';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import { Subscription } from 'src/libs/models/Billing.model';
import { StateService } from 'src/libs/state/state.service';

@Component({
  selector: 'fini-server-addons',
  standalone: true,
  imports: [MatProgressBarModule],
  templateUrl: './server-addons.component.html',
  styleUrl: './server-addons.component.scss',
})
export class ServerAddonsComponent implements OnInit {
  constructor(
    private router: Router,
    private api: ApiService,
    private toast: HotToastService,
    private state: StateService,
  ) {}

  subs: Subscription[] = [];
  loading = signal(true);

  ngOnInit(): void {
    this.api
      .get<
        ApiResponse<{ subscriptions: Subscription[] }>
      >('/user/billing/subscriptions')
      .subscribe((res) => {
        this.loading.set(false);
        if (res.success) {
          this.subs = res.subscriptions;
        }
      });
  }

  openSubManagement() {
    const currentServer = this.state.get('selectedServer');
    const subscription = this.subs.find(
      (s) => s.fini_data.server_id === currentServer?.id,
    );
    if (subscription) {
      this.router.navigate([
        '/profile/billing/subscriptions',
        subscription.fini_data.id,
      ]);
    } else {
      this.router.navigate(['/profile/billing/subscriptions']);
    }
  }
}
