import { Component, HostListener, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  GuidedTour,
  GuidedTourService,
  Orientation,
} from 'finiac-ngx-guided-tour';
import { GuideService } from './guides.service';

@Component({
    selector: 'fini-guides',
    templateUrl: './guides.component.html',
    styleUrls: ['./guides.component.scss'],
    standalone: false
})
export class GuidesComponent {
  constructor(
    private tourService: GuidedTourService,
    private guideService: GuideService,
    private router: Router
  ) {}

  show = signal(false);

  @HostListener('window:guide:start', ['$event'])
  guideInit(event: Event) {
    const guide = (event as CustomEvent).detail;
    switch (guide) {
      case 'setup': {
        this.router.navigate(['/setup']);
        break;
      }
      case 'welcome': {
        this.tourService.startTour(this.WelcomeGuide);
        break;
      }
    }
  }

  WelcomeGuide: GuidedTour = {
    tourId: 'tutorial',
    completeCallback: () => {
      this.guideService.complete('welcome');
      this.router.navigate(['/dashboard']);
    },
    skipCallback: () => {
      this.guideService.cancel('welcome');
      this.router.navigate(['/dashboard']);
    },

    steps: [
      {
        title: 'Welcome to FiniAC!',
        content:
          'This is the getting started guide aimed at familiarizing you with the FiniAC user interface. Click "Next" to start.',
        action: () => {
          this.guideService.start('welcome');
        },
      },
      {
        title: 'Users',
        content: 'This is the Users page.',
        selector: '#users',
        orientation: Orientation.Bottom,
        action: () => {
          this.router.navigate(['/users']);
        },
      },
      {
        title: 'Subusers',
        content:
          "you can see all of your users here, you can add sub-users, and manage their permissions. This is required as we don't allow credential sharing.",
        selector: '#tour-sub-users',
        orientation: Orientation.Center,
      },
      {
        title: 'Profile',
        content: 'This is the Profile page.',
        selector: '#header-profile',
        orientation: Orientation.Bottom,
        action: () => {
          this.router.navigate(['/profile']);
        },
      },
      {
        title: 'Profile - Get started',
        content:
          "In the profile page, you can see your profile information as well as setup account integrations. We'll start with Discord.",
      },
      {
        title: 'Discord',
        content:
          'This is the Discord linking section. We recommend you use the Discord app to link your account, this will allow you to get Customer Support, Login with Discord, and more.',
        selector: '#tour-discord',
        orientation: Orientation.Bottom,
      },
      {
        title: '2FA',
        content:
          'This is the 2FA section. We recommend you use Google Authenticator to generate a secret key and add it to your account. Once 2FA is setup, you should also save your 2FA Recovery key.',
        selector: '#tour-2fa',
        orientation: Orientation.Bottom,
      },
      {
        title: 'Preferences',
        content:
          'This is the Preferences page. Here you can change your notification preferences.',
        selector: '#tour-preferences',
        orientation: Orientation.Top,
      },
      {
        title: 'Account Settings',
        content:
          'This is the Account Settings page. Here you can change your account settings, such as your email, and password (soontm).',
        selector: '#tour-account-settings',
        orientation: Orientation.Top,
      },
      {
        title: 'Threats',
        content:
          "This is the Threats page. Here you can see and manage bans, and check account id's for which cheat they used.",
        selector: '#threats',
        action: () => {
          this.router.navigate(['/threats']);
        },
        orientation: Orientation.Bottom,
      },
      {
        title: 'Threats - Search & Create',
        content:
          'In the search and create section, you can search for bans, and create new local bans.',
        selector: '#threats-search',
      },
      {
        title: 'Threats - Ban Lookup',
        content:
          'In the ban lookup section, you can see the details of a ban, such as the cheat used.',
        selector: '#threats-lookup',
        orientation: Orientation.Left,
      },
      {
        title: 'Configs',
        content:
          'This is the Configs page. Here you can see and manage your configs, such as your server settings.',
        selector: '#configs',
        orientation: Orientation.Bottom,
        action: () => {
          this.router.navigate(['/configs']);
        },
      },
      {
        title: 'Configs - Modify',
        content:
          'In the config editor, change all of your server settings for FiniAC, tune your anti-cheat to reduce false positives and increase your detection rate. For more information, see the <a href="/docs">Documentation</a>.',
        selector: '#configs-editor',
        orientation: Orientation.Center,
      },
      {
        title: 'Finished',
        content:
          'You have completed the FiniAC tutorial. You can now start using FiniAC, Reach out to us on Discord for support.',
        action: () => {
          this.guideService.complete('welcome');
          this.router.navigate(['/dashboard']);
        },
      },
    ],
  };
}
