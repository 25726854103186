<div [className]="'whitelist-item ' + whitelist.status">
  <div class="info">
    <p (mouseenter)="showHovercard(whitelist)" (mouseleave)="hideHovercard()">
      <b>{{ 'whitelist.submission.form.uid' | translate }}: </b>
      <a [href]="'/threats?s=' + whitelist.player_uid" target="_blank">
        <span class="uid">
          {{ whitelist.player_uid }}
        </span>
      </a>
    </p>
    <p>
      <b>{{ 'whitelist.status' | translate }}:</b>
      {{ whitelist.status | capitalize }}
    </p>
    <p>
      <b>{{ 'generic.server' | translate }}: </b>
      <a
        *ngIf="'user' | state | async | access : 'WHITELISTS'"
        [routerLink]="['/utils/server/' + whitelist.server_id]"
      >
        <span class="uid">
          {{ whitelist.server_name }}
        </span>
      </a>
      <span *ngIf="!('user' | state | async | access : 'WHITELISTS')">
        {{ whitelist.server_name }}
      </span>
    </p>
    <p>
      <b>{{ 'generic.requested' | translate }}:</b>
      {{ whitelist.time_created * 1000 | date : 'yyyy/MM/dd HH:mm' }}
    </p>
    <p>
      <b>{{ 'generic.requested-by' | translate }}:</b>
      {{ whitelist.submitter_name }}
    </p>
    <p>
      <b>{{ 'generic.banned-since' | translate }}:</b>
      {{ daysSinceBan(whitelist.banned_since) }}
      {{ 'generic.days-ago' | translate }}
    </p>
    <p>
      <b>{{ 'generic.ban-count' | translate }}:</b>
      {{ whitelist.ban_count }}
    </p>
    <ng-container
      *ngIf="whitelist.reviewer_id && whitelist.reviewer_id !== '0'"
    >
      <p>
        <b>{{ 'generic.updated' | translate }}:</b>
        {{ whitelist.time_updated * 1000 | date : 'yyyy/MM/dd HH:mm' }}
      </p>
      <p>
        <b>{{ 'generic.updated-by' | translate }}:</b>
        {{ whitelist.reviewer_name ?? 'System' }}
      </p>
    </ng-container>
  </div>

  <div class="mt-4">
    <label>
      <b>{{ 'whitelist.submission.form.reason' | translate }}</b>
    </label>
    <textarea
      spellcheck="false"
      rows="5"
      [(ngModel)]="whitelist.reason"
      disabled=""
    >
    </textarea>

    <label>
      <b>{{ 'whitelist.submission.form.admittance' | translate }}</b>
    </label>
    <textarea
      spellcheck="false"
      rows="1"
      [(ngModel)]="whitelist.admittance"
      disabled=""
    >
    </textarea>

    <ng-container *ngIf="isResponseVisible()">
      <label>
        <b>{{ 'whitelist.submission.form.response' | translate }}</b>
      </label>
      <textarea
        spellcheck="false"
        rows="3"
        [(ngModel)]="whitelist.response"
        (change)="update()"
        [disabled]="!('user' | state | async | access : 'WHITELISTS')"
      >
      </textarea>
    </ng-container>
  </div>

  <div class="actions">
    <ng-container *ngIf="'user' | state | async | access : 'WHITELISTS'">
      <button class="button gray" (click)="accept()">Accept</button>
      <button class="button gray" (click)="reject()">Reject</button>
    </ng-container>
    <button
      class="button gray"
      (click)="remove()"
      *ngIf="whitelist.status != 'removed'"
    >
      Remove
    </button>
  </div>
</div>
