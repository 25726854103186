import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ApiWhitelistRequest } from 'src/libs/models/Whitelist.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { WhitelistItemComponent } from '../whitelist-item/whitelist-item.component';

@Component({
  selector: 'fini-whitelist-list',
  templateUrl: './whitelist-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./whitelist-list.component.scss'],
  imports: [
    CommonModule,
    CustomPipesModule,
    WhitelistItemComponent
  ],
})
export class WhitelistListComponent {
  @Input() whitelists: ApiWhitelistRequest[];
  @Input() total: number;
  @Output() pageNum = new EventEmitter<number>();

  page = signal(0);
  pageSize = 100;

  changePage(num: number) {
    this.page.update((val) =>
      Math.max(0, Math.min(this.getPageCount(), val + num))
    );
    this.pageNum.emit(this.page());
  }

  getPageCount() {
    return Math.floor(this.total / this.pageSize);
  }
}
