import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HoverCardService {
  constructor() {}

  show(component: string, props: any) {
    window.dispatchEvent(
      new CustomEvent('hovercard:trigger', {
        detail: {
          component,
          props,
        },
      })
    );
  }

  hide() {
    window.dispatchEvent(new CustomEvent('hovercard:hide'));
  }
}
