import {
  Component,
  computed,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../auth/auth.service';
import { GuideService } from '../guides/guides.service';
import { GuideState } from '../models/Guide.model';
import { FiniNotification } from '../models/Notifications.model';
import { ApiServer } from '../models/Profile.model';
import { NotificationsService } from '../notifications/notifications.service';
import { ServerService } from '../server/server.service';
import { StateService } from '../state/state.service';

@Component({
  selector: 'fini-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private state: StateService,
    private auth: AuthService,
    private selectedServer: ServerService,
    private translate: TranslateService,
    private notifs: NotificationsService,
    private router: Router,
    private toast: HotToastService,
    private guideService: GuideService,
  ) {}

  @ViewChild('dropdownServer') elementRefServer: ElementRef;
  @ViewChild('dropdownLang') elementRefLang: ElementRef;
  @ViewChild('serverSearch') search: ElementRef<HTMLInputElement>;

  menu = signal(false);
  show = {
    languages: signal(false),
    themes: signal(false),
    notifications: signal(false),
    guides: signal(false),
  };
  serverList = signal(false);
  languageList = signal(false);
  notificationList = signal(false);
  server = signal<ApiServer | null>(null);
  servers = this.state.get<ApiServer[]>('servers');

  filterServers = signal('');
  languages = this.state.get('languages');
  currentLanguage = signal(this.translate.currentLang);

  notifications = signal<FiniNotification[]>([]);
  unreadCount = computed(
    () => this.notifications().filter((n) => n.status === 'unread').length,
  );
  NO_SNOW = false;
  selectedServerIndex = signal(-1);
  filteredServers = computed(() => {
    return this.servers.filter((server) =>
      server.name.toLowerCase().includes(this.filterServers().toLowerCase()),
    );
  });

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((lang) => {
      this.currentLanguage.set(lang.lang);
    });
    const tutorial = this.state.get('guides');
    if (Object.keys(tutorial).length === 0) {
      this.toast.info('Need help? Choose a guide from the menu.', {
        dismissible: true,
      });
      this.state.set({
        guides: {
          welcome: GuideState.Seen,
        },
      });
    }
    if (tutorial?.welcome === GuideState.Started) {
      this.guideService.trigger('welcome');
    }

    if (this.state.get('user')) {
      window.addEventListener(
        'notifications:update',
        this.notificationUpdateHandler.bind(this),
      );

      this.notifs.realtime();
      this.state.select('notifications').subscribe((notifications) => {
        this.notifications.set(notifications);
      });
    }

    this.state.select<ApiServer>('selectedServer').subscribe((x) => {
      if (x) {
        this.server.set(x);
      }
    });

    if (window?.localStorage?.getItem('NO_SNOW') !== null) {
      this.NO_SNOW = true;
    }
  }

  ngOnDestroy(): void {
    if (window._notificationInterval) {
      clearInterval(window._notificationInterval);
    }
    window.removeEventListener(
      'notifications:update',
      this.notificationUpdateHandler.bind(this),
    );
  }

  notificationUpdateHandler(event: Event) {
    //@ts-expect-error
    this.notifications.set(event.detail);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const containerElem = document.querySelectorAll('.dropdown');

    for (const el of Object.values(containerElem)) {
      for (let i = 0; i < el.childNodes.length; i++) {
        if (el.childNodes[i].contains(target)) {
          return;
        }
      }
    }
    this.toggleLanguageList(false);
    this.toggleServerList(false);
    this.toggleNotificationList(false);
    this.toggleMenu(false);
  }

  @HostListener('document:keydown', ['$event'])
  onKey(event: KeyboardEvent) {
    if (
      event.code === 'Period' &&
      ['BODY', 'A'].includes((event.target as HTMLElement)?.tagName)
    ) {
      this.toggleServerList(true);
      return;
    }

    if (!this.serverList()) return;

    switch (event.code) {
      case 'ArrowDown':
        event.preventDefault();
        this.selectedServerIndex.update((i) =>
          Math.min(i + 1, this.filteredServers().length - 1),
        );
        this.scrollSelectedIntoView();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.selectedServerIndex.update((i) => Math.max(i - 1, -1));
        this.scrollSelectedIntoView();
        break;
      case 'Enter':
        event.preventDefault();
        if (this.selectedServerIndex() >= 0) {
          const selectedServer =
            this.filteredServers()[this.selectedServerIndex()];
          if (selectedServer) {
            this.changeServer(selectedServer);
            this.toggleServerList(false);
          }
        }
        break;
    }
  }

  @HostListener('document:keydown.escape')
  onEscape() {
    if (this.serverList()) {
      this.toggleServerList(false);
    }
    if (this.menu()) {
      this.toggleMenu(false);
    }
  }

  toggleServerList(state: boolean) {
    this.serverList.set(state);
    if (state) {
      this.selectedServerIndex.set(-1);
    }
    setTimeout(() => {
      if (this.search) {
        this.search.nativeElement.focus();
      }
    }, 0);
  }
  toggleLanguageList(state: boolean) {
    this.languageList.set(state);
  }
  toggleNotificationList(state: boolean) {
    this.notificationList.set(state);
  }
  toggleMenu(state: boolean) {
    this.menu.set(state);
  }
  toggleMenuItem(item: keyof typeof this.show) {
    this.show[item].set(!this.show[item]());
  }

  visitNotification(notification: FiniNotification) {
    // this.toggleNotificationList(false);
    this.notifs.read(notification.id);

    if (notification.link) {
      const [path, query] = notification.link.split('?');

      // turns ?a=1&b=2 into { a: 1, b: 2 }
      const params = query
        ? query.split('&').reduce<{ [key: string]: string }>((acc, curr) => {
            const [key, value] = curr.split('=');
            acc[key] = value;
            return acc;
          }, {})
        : {};
      this.router.navigate([path], { queryParams: params });
    }
  }

  clearAllNotifications() {
    this.notifs.readAll();
  }

  changeServer(server: ApiServer) {
    this.selectedServer.set(server.id);
  }

  changeLanguage(lang: string) {
    this.state.set({ language: lang });
    this.translate.use(lang);
  }

  toggleTheme() {
    const theme = this.state.get('theme');
    this.state.set({ theme: theme === 'dark' ? 'light' : 'dark' });
  }

  startGuide(tutorial: string) {
    this.guideService.trigger(tutorial);
    this.show.guides.set(false);
    this.menu.set(false);
  }

  logout() {
    this.auth.logout();
  }

  statuspage() {
    window.open('https://status.fini.dev', '_blank');
  }

  no_snow() {
    window?.localStorage?.setItem('NO_SNOW', 'true');
    window?.location?.reload();
  }

  scrollSelectedIntoView() {
    setTimeout(() => {
      const selectedElement = document.querySelector('.server-item.selected');
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    });
  }
}
