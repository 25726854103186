import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';

@Component({
    selector: 'fini-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HomepageComponent implements OnInit {
  constructor() {
    if (window.location.origin.includes('fini.dev')) {
      window.location.href = 'https://site.fini.dev';
    } else {
      window.location.href = window.location.href + '/login';
      this.isDev.set(true);
    }
  }

  isDev = signal(false);

  ngOnInit(): void {}
}
