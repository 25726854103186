import { CommonModule } from '@angular/common';
import { Component, computed, input, OnInit } from '@angular/core';
import { ApiConfigItem } from 'src/libs/models/Configs.model';

@Component({
    selector: 'fini-hc-configs',
    imports: [CommonModule],
    templateUrl: './hc-configs.component.html',
    styleUrl: './hc-configs.component.scss'
})
export class HcConfigsComponent implements OnInit {
  config = input.required<ApiConfigItem>();

  configText = computed(() => {
    const config = this.config();

    const { docs } = config;

    const parts = docs?.split('\n');
    return parts[0];
  });

  configDetectionBadges = computed(() => {
    const config = this.config();
    const { docs } = config;

    const parts = docs?.split('\n');

    if (parts.length < 2) return [];

    // Extract badges from the second part of the docs
    const badgesString = parts[1];
    const badges =
      badgesString.match(/<([^>]+)>/g)?.map((badge) => badge.slice(1, -1)) ||
      [];

    return badges;
  });

  ngOnInit(): void {}
}
