import {
  Component,
  ElementRef,
  HostListener,
  signal,
  ViewChild,
} from '@angular/core';
import { HcBanDetailsComponent } from './hc-ban-details/hc-ban-details.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { HcConfigsComponent } from './hc-configs/hc-configs.component';

const HOVERCARD_TIMEOUT = 5000;
const HC_COMPONENT_MAP: Record<string, any> = {
  'ban-details': HcBanDetailsComponent,
  unauthorised: UnauthorisedComponent,
  'config-tooltip': HcConfigsComponent,
};

@Component({
    selector: 'fini-hovercard-outlet',
    templateUrl: './hover-card.component.html',
    styleUrl: './hover-card.component.scss',
    standalone: false
})
export class HoverCardComponent {
  FeatureFlag = true;

  @ViewChild('hovercardContent') hovercardContent: ElementRef;
  hoverCardActive = signal(false);

  content = signal(null);
  contentInputs = signal({});

  viewport = signal<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  mouseposition = signal<{ x: number; y: number }>({ x: 0, y: 0 });
  setpos: { x: number; y: number } = { x: 0, y: 0 };
  hidey: number = null;

  ngOnDestroy(): void {
    if (this.hidey) {
      clearTimeout(this.hidey);
    }
  }

  @HostListener('window:hovercard:trigger', ['$event'])
  async trigger(event: any) {
    if (!this.FeatureFlag) return;
    if (this.hidey) return;
    this.hoverCardActive.set(true);

    this.content.set(HC_COMPONENT_MAP[event.detail?.component]);
    this.contentInputs.set(event.detail?.props);

    const mpos = this.mouseposition();
    const vp = this.viewport();

    // Dynamically adjust the position of the hovercard to fit on the screen
    // if it's too big
    this.setpos = {
      x:
        vp.width - mpos.x < 300 ? mpos.x - (300 - (vp.width - mpos.x)) : mpos.x,
      y:
        vp.height - (mpos.y + 30) < 300
          ? mpos.y - (300 - (vp.height - (mpos.y + 30)))
          : mpos.y,
    };
  }

  @HostListener('window:hovercard:hide')
  handleHovercardHide() {
    if (this.hidey) return;

    this.hidey = window.setTimeout(() => {
      if (this.shouldHide()) {
        this.hoverCardActive.set(false);
        window.setTimeout(() => {
          this.contentInputs.set({});
        }, 200);
      }
      this.hidey = null;
    }, 100);
  }

  shouldHide() {
    const mpos = this.mouseposition();
    const contentBounds =
      this.hovercardContent?.nativeElement.getBoundingClientRect();
    return (
      this.hoverCardActive() &&
      (mpos.x < contentBounds.left ||
        mpos.x > contentBounds.right ||
        mpos.y < contentBounds.top ||
        mpos.y > contentBounds.bottom)
    );
  }

  @HostListener('window:mousemove', ['$event'])
  handleMousemove(event: MouseEvent) {
    this.mouseposition.set({ x: event.clientX, y: event.clientY });
  }

  @HostListener('window:resize', ['$event'])
  handleResize(event: Event) {
    this.viewport.set({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
}
