<div class="relative page-title">
  <h1>{{ 'whitelist.title' | translate }}</h1>
  <span>{{ 'whitelist.subtitle' | translate }}</span>
  <div class="topright">
    @if (isLoading() || !whitelist()) {
      <div class="placeholder alt h-3 w-7"></div>
    } @else {
      <div class="inner">
        <fini-tooltip [left]="true">
          <div class="d-flex flex-column">
            <span class="small">Average wait: </span>
            <span class="value">
              {{ whitelist()?.average_response_time.split('.')[0] }}
            </span>
          </div>
          <span content style="text-wrap: nowrap">Weekly average</span>
        </fini-tooltip>
      </div>
    }
  </div>
</div>

<div class="whitelist-page" *ngIf="'fivem' | game | async; else otherGames">
  <div class="content-group">
    <fini-whitelist-submission
      (submitted)="reloadList()"
    ></fini-whitelist-submission>
  </div>

    @if (!oldIsLoading() && old_whitelist()?.length > 0) {
      <div class="whitelist content-group">
        <h2>Old Whitelists</h2>
        <p>
          These are whitelists that were sent before the new system was implemented.
        </p>
        <div
          class="content-group alt mt-2"
          *ngFor="let wl of old_whitelist()"
        >
          <p>
            <b>Player UID: </b>
            <a target="_blank" [href]="'/threats?s=' + wl.player_uid">
              <span class="uid">{{ wl.player_uid }}</span>
            </a>
          </p>
          <p>
            <b>Known as: </b>
            <span>{{ wl.names.split('&&').join(', ') }}</span>
          </p>
          <div class="d-flex flex-row column-gap-2">
            <button
              class="w-100 button primary"
              (click)="transferToNewWhitelist(wl.player_uid)"
            >
              Transfer
            </button>
            <button
              class="w-100 button primary"
              (click)="removeOldWhitelist(wl.player_uid)"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    }

    @if (isLoading()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
      @let wl = whitelist();
      <div class="whitelist">
        <ng-container *ngIf="wasListShown()">
          <div class="content-group mb-4">
            <h2>{{ 'whitelist.requests.title' | translate }}</h2>
            <p>
              View and edit the whitelists that have been sent for you server.
              <br />
              {{ 'whitelist.requests.slots-used' | translate }}
              {{ wl.used_slots }} / {{ wl.slots }}
            </p>

            <ng-container
              class="w-100"
              *ngIf="'user' | state | async | access : 'WHITELISTS'"
            >
              <div class="show_all">
                <label>Showing {{ showAll() ? 'All' : 'Local' }}</label>
                <mat-slide-toggle
                  class="float-right"
                  hideIcon="true"
                  type="bool"
                  [ngModel]="showAll()"
                  (ngModelChange)="showAll.set($event); reloadList()"
                >
                </mat-slide-toggle>
              </div>
              <input
                type="text"
                placeholder="Search"
                class="input-text w-100"
                [formControl]="searchCtrl"
              />
            </ng-container>
          </div>

          <fini-whitelist-list
            [whitelists]="wl.requests"
            [total]="wl.used_slots"
            (pageNum)="page.set($event); reloadList()"
          ></fini-whitelist-list>
        </ng-container>
      </div>
    }
    <div class="d-flex content-group no-padding justify-content-center">
      <fini-pagination
        (itemsPerPageChange)="onItemsPerPageChange($event)"
        [totalItems]="whitelistCount()"
        [page]="page"
        (pageChange)="reloadList()"
      ></fini-pagination>
    </div>
</div>

<ng-template #otherGames>
  <p class="mt-4">
    Whitelisting banned players on Arma is done via the
    <a routerLink="/configs">config file</a>.
  </p>
</ng-template>

<ng-template #loading>
  <div class="loading mt-5 text-center">
    <div class="spinner-border"></div>
  </div>
</ng-template>
