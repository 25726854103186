<div class="whitelist-request">
  <h2>{{ 'whitelist.submission.title' | translate }}</h2>
  <p>{{ 'whitelist.submission.subtitle' | translate }}</p>
  <p class="err-msg">{{ message() }}</p>
  <div [ngSwitch]="state()">
    <ng-container *ngSwitchCase="'initial'">
      <input
        type="text"
        class="input-text"
        [placeholder]="'whitelist.submission.form.uid' | translate"
        [(ngModel)]="whitelist.player_uid"
      />
      <button
        class="button primary"
        (click)="checkUid()"
        [disabled]="isLoading()"
      >
        {{ 'generic.next' | translate }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'reason'">
      <textarea
        class="input-text"
        rows="4"
        [placeholder]="'whitelist.submission.form.reason' | translate"
        [(ngModel)]="whitelist.reason"
      ></textarea>
      <input
        type="text"
        class="input-text"
        [placeholder]="'whitelist.submission.form.admittance' | translate"
        [(ngModel)]="whitelist.admittance"
      />
      <button class="button primary" (click)="preview()">Next</button>
    </ng-container>
    <ng-container *ngSwitchCase="'preview'">
      <div class="preview">
        <p class="info">
          {{ 'whitelist.submission.form.preview.correct' | translate }}
        </p>
        <p>
          <span>{{ 'generic.server' | translate }}:</span>
          {{ ('selectedServer' | state | async)?.name }}
        </p>
        <p>
          <span>{{ 'whitelist.submission.form.uid' | translate }}:</span>
          {{ whitelist.player_uid }}
        </p>
        <p>
          <span>{{ 'whitelist.submission.form.reason' | translate }}:</span>
          {{ whitelist.reason }}
        </p>
        <p>
          <span
            >{{ 'whitelist.submission.form.admittance' | translate }}:
          </span>
          {{ whitelist.admittance }}
        </p>
        <button
          class="button primary"
          (click)="submit()"
          [disabled]="isLoading()"
        >
          {{ 'generic.submit' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
