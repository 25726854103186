import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/libs/api/api.service';
import { ModalModule } from 'src/libs/modal/modal.module';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import {
  ApiWhitelistList,
  OldApiWhitelistList,
} from 'src/libs/models/Whitelist.model';
import { PaginationModule } from 'src/libs/pagination/pagination.module';
import { PermissionService } from 'src/libs/permission/permission.service';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { TooltipModule } from 'src/libs/tooltip/tooltip.module';
import { WhitelistListComponent } from './whitelist-list/whitelist-list.component';
import { WhitelistSubmissionComponent } from './whitelist-submission/whitelist-submission.component';

@Component({
  selector: 'fini-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    CustomPipesModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    TooltipModule,
    PaginationModule,
    MatProgressBarModule,
    WhitelistListComponent,
    WhitelistSubmissionComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhitelistComponent implements OnInit {
  constructor(private api: ApiService, private perm: PermissionService) { }

  showAll = signal(true);
  page = signal(1);
  pageSize = signal(25);
  whitelistCount = signal(0);

  searchCtrl = new UntypedFormControl();
  isLoading = signal(false);
  oldIsLoading = signal(false);
  wasListShown = signal(false);

  whitelist = signal<ApiWhitelistList>(null);
  old_whitelist = signal<OldApiWhitelistList['whitelists']>(null);

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.page.set(1))
      )
      .subscribe((res) => {
        this.reloadList();
      });
  }

  getWhitelists() {
    return this.perm.hasGlobalPermission('WHITELISTS').pipe(
      switchMap((isAdmin) =>
        this.api.post<ApiWhitelistList>('whitelist/list', {
          all: this.showAll() && isAdmin,
          page: this.page(),
          page_size: this.pageSize(),
          search: this.searchCtrl.value,
        })
      ),
      tap((list) => {
        list?.requests?.length && this.wasListShown.set(true);
        this.whitelistCount.set(list.count);
      })
    );
  }

  getOldWhitelists() {
    return this.perm.hasGlobalPermission('WHITELISTS').pipe(
      switchMap((isAdmin) =>
        this.api.post<OldApiWhitelistList>('whitelist/old/list', {
          all: this.showAll() && isAdmin,
        })
      )
    );
  }

  transferToNewWhitelist(id: string) {
    this.oldIsLoading.set(true);
    this.api
      .post<ApiResponse>('whitelist/old/transfer', { player_uid: id })
      .subscribe((res) => {
        if (res.success) {
          this.reloadList();
        }
      });
  }

  removeOldWhitelist(id: string) {
    this.oldIsLoading.set(true);
    this.api
      .post<ApiResponse>('whitelist/old/remove', { player_uid: id })
      .subscribe((res) => {
        if (res.success) {
          this.reloadList();
        }
      });
  }

  reloadList() {
    this.isLoading.set(true);
    this.getWhitelists().subscribe((res) => {
      this.whitelist.set(res);
      this.isLoading.set(false);
    });
    this.getOldWhitelists().subscribe((res) => {
      this.old_whitelist.set(res.whitelists);
      this.oldIsLoading.set(false);
    });
  }

  onItemsPerPageChange(itemsPerPage: { initial: boolean; value: number }) {
    this.pageSize.set(itemsPerPage.value);
    this.page.set(1);

    // this will also do the initial reload
    this.reloadList();
  }
}
