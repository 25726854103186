import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { StateService } from '../state/state.service';
import { ApiServer } from '../models/Profile.model';

@Component({
  selector: 'fini-banners',
  imports: [CommonModule],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.scss'
})
export class BannersComponent implements OnInit {
  constructor(private state: StateService) { }

  banners = signal<
    {
      message: string;
      type: 'warning' | 'error';
      icon: string;
    }[]
  >([]);

  ngOnInit(): void {
    const server = this.state.get<ApiServer>('selectedServer');
    if (!server) return;

    const endTime = server.end_time;

    if (endTime > 0 && endTime < Date.now() / 1000) {
      this.banners.update((banners) => [
        ...banners,
        {
          message: 'Your subscription has expired',
          type: 'error',
          icon: 'error',
        },
      ]);
    }
    if (server?.status === "terminated") {
      this.banners.update((banners) => [
        ...banners,
        {
          message: 'Your license has been suspended, please contact support.',
          type: 'error',
          icon: 'error',
        },
      ]);
    }
  }
}
