<div class="page-title">
  <h2 class="mb-0">Server Addons</h2>
</div>

@if (loading()) {
  <div class="loading mt-5 text-center">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
} @else {
  <div class="addon-list">
    <div class="addon">
      <div class="addon-content">
        <h3>Advanced Identity Monitoring</h3>
        <p>
          Advanced Identity Monitoring is FiniAC's premium anti-spoofing addon.
          It provides additional checks to prevent players from spoofing their
          identity.
        </p>
      </div>
      <button class="button primary" (click)="openSubManagement()">
        View Details
      </button>
    </div>

    <div class="addon">
      <div class="addon-content">
        <h3>Additional Whitelisting Slots</h3>
        <p>
          Does your community need more whitelisting slots? If you've filled up
          all the slots, you can purchase additional ones.
        </p>
      </div>
      <button class="button primary" (click)="openSubManagement()">
        View Details
      </button>
    </div>
  </div>
}
