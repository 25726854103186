import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { StateService } from '../state/state.service';

import { AuthService } from './auth.service';
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(
    private auth: AuthService,
    private perm: PermissionService,
    private router: Router,
  ) {}

  public loading$ = new BehaviorSubject(false);

  loader$: Subscription;

  canActivate(route: ActivatedRouteSnapshot) {
    this.loader$ = of(null)
      .pipe(delay(200))
      .subscribe((x) => this.loading$.next(true));

    const res = this.auth.profile().pipe(
      switchMap((_) => this.perm.hasGlobalPermission('ADMIN')),
      map((isAdmin) => {
        this.loader$.unsubscribe();
        this.loading$.next(false);

        if (!isAdmin) {
          this.router.navigate(['/dashboard']);
        }
        return isAdmin;
      }),
    );

    return res;
  }
}
